<!-- 入力フォームページアンカー -->
<a id="generateInputFormTop"></a>
<!-- 入力フォーム -->
<div class="generateInputForm" *ngIf="!confirmationFlag">
  <form [formGroup]="generateInputForm">
    <!-- 入力情報出力領域 -->
    <p-table
      [value]="generateInputList"
      styleClass="p-datatable-sm generate-input"
    >
      <!-- 入力情報タイトル -->
      <ng-template pTemplate="header">
        <tr>
          <th colspan="2">入力</th>
        </tr>
      </ng-template>
      <!-- 入力情報出力箇所 -->
      <ng-template pTemplate="body" let-generateInputInformation>
        <!-- 画面表示不可以外の場合 -->
        <tr *ngIf="generateInputInformation.column_input_update != '4'">
          <!-- カラム名称 -->
          <td class="label-center">
            <label class="p-col-fixed">
              {{ generateInputInformation.column_name }}

              <!-- 項目定義マスタ.入力必須有無に'1'が存在する場合、必須を表示 -->
              <span
                *ngIf="generateInputInformation.column_input_require == '1'"
                class="p-tag p-tag-danger require"
              >
                必須
              </span>
            </label>
          </td>

          <!-- 入力項目 -->
          <td class="input-form">
            <!-- 入力項目タイプを判定 -->
            <!-- テキスト -->
            <div
              class="ui-fluid"
              *ngIf="generateInputInformation.input_type == 'text'"
            >
              <input
                [name]="generateInputInformation.column_id"
                type="text"
                [maxlength]="generateInputInformation.column_hsize"
                [class.errorItem]="
                  checkErrorItem(generateInputInformation.column_id)
                "
                [formControlName]="generateInputInformation.column_id"
                pInputText
                *ngIf="generateInputInformation.column_id != 'suppliers_code'"
              />
              <input
                [name]="generateInputInformation.column_id"
                type="text"
                [maxlength]="generateInputInformation.column_hsize"
                [class.errorItem]="
                  checkErrorItem(generateInputInformation.column_id)
                "
                [formControlName]="generateInputInformation.column_id"
                pInputText
                (keydown)="blockNonAlphanumeric($event)"
                *ngIf="generateInputInformation.column_id == 'suppliers_code'"
              />
            </div>

            <!-- 日付 -->
            <div
              class="ui-fluid"
              *ngIf="generateInputInformation.input_type == 'date'"
            >
              <p-calendar
                [name]="generateInputInformation.column_id"
                [showIcon]="true"
                [showButtonBar]="true"
                [yearNavigator]="true"
                [monthNavigator]="true"
                dateFormat="yy/mm/dd"
                dataType="string"
                yearRange="2000:2999"
                [inputStyleClass]="
                  checkErrorItem(generateInputInformation.column_id)
                    ? 'errorItem'
                    : ''
                "
                [formControlName]="generateInputInformation.column_id"
                translate
              >
              </p-calendar>
            </div>

            <!-- 年/月 -->
            <div
              class="ui-fluid"
              *ngIf="generateInputInformation.input_type == 'year_month'"
            >
              <p-calendar
                [name]="generateInputInformation.column_id"
                [showIcon]="true"
                [showButtonBar]="true"
                [yearNavigator]="true"
                [monthNavigator]="true"
                dateFormat="yy/mm"
                view="month"
                dataType="string"
                yearRange="2000:2999"
                [inputStyleClass]="
                  checkErrorItem(generateInputInformation.column_id)
                    ? 'errorItem'
                    : ''
                "
                [formControlName]="generateInputInformation.column_id"
                translate
              >
              </p-calendar>
            </div>

            <!-- 数字 -->
            <div
              class="ui-fluid"
              *ngIf="generateInputInformation.input_type == 'number'"
            >
              <input
                *ngIf="generateInputInformation.column_ime == 'NUMBER_CODE_WITH_MAX_LENGTH'"
                [name]="generateInputInformation.column_id"
                type="number"
                [class.errorItem]="
                  checkErrorItem(generateInputInformation.column_id)
                "
                [formControlName]="generateInputInformation.column_id"
                pInputText
                (input)="validateLengthNumber($event, generateInputInformation.column_hsize)"
                (keypress)="blockNonInteger($event)"
                />
              <input
                *ngIf="generateInputInformation.column_ime != 'NUMBER_CODE_WITH_MAX_LENGTH'"
                [name]="generateInputInformation.column_id"
                type="number"
                [class.errorItem]="
                  checkErrorItem(generateInputInformation.column_id)
                "
                [formControlName]="generateInputInformation.column_id"
                pInputText
              />
            </div>

            <!-- チェックボックス -->
            <div
              class="ui-fluid"
              *ngIf="generateInputInformation.input_type == 'checkbox'"
              [class.errorItemBorder]="
                checkErrorItem(generateInputInformation.column_id)
              "
            >
              <div
                class="p-field-checkbox"
                *ngFor="
                  let code_list of generateInputInformation.column_code_list_multi
                "
              >
                <p-checkbox
                  [name]="generateInputInformation.column_id"
                  [label]="code_list.name"
                  [value]="code_list.value"
                  [formControl]="
                    generateInputForm.controls[
                      generateInputInformation.column_id
                    ]
                  "
                >
                </p-checkbox>
              </div>
            </div>

            <!-- ラジオボタン -->
            <div
              class="ui-fluid"
              *ngIf="generateInputInformation.input_type == 'radio'"
              [class.errorItemBorder]="
                checkErrorItem(generateInputInformation.column_id)
              "
            >
              <div
                class="p-field-radiobutton"
                *ngFor="
                  let column of generateInputInformation.column_code_list_multi
                "
              >
                <p-radioButton
                  [name]="generateInputInformation.column_id"
                  [label]="column.name"
                  [value]="column.value"
                  [formControlName]="generateInputInformation.column_id"
                  (click)="onRadioChange(generateInputInformation.column_id, column.value, generateInputInformation.column_input_update)"
                >
                </p-radioButton>
              </div>
            </div>

            <!-- ラジオボタン -->
            <div
              class="ui-fluid"
              *ngIf="generateInputInformation.input_type == 'radio_input_number'"
              [class.errorItemBorder]="
                checkErrorItem(generateInputInformation.column_id)
              "
            >
              <app-generate-radio-input [name]="generateInputInformation.column_id"
                [options]="generateInputInformation.column_code_list_multi"
                [defautlOption]="generateInputInformation.column_defualt" 
                [formControlName]="generateInputInformation.column_id">
              </app-generate-radio-input>
            </div>

            <div
              class="ui-fluid"
              *ngIf="generateInputInformation.input_type == 'checkbox_tree'"
              [class.errorItemBorder]="
                checkErrorItem(generateInputInformation.column_id)
              "
            >
              <app-generate-tree-select [name]="generateInputInformation.column_id"
                [options]="generateInputInformation.column_code_list_multi"
                [formControlName]="generateInputInformation.column_id">
              </app-generate-tree-select>
            </div>

            <!-- シングルセレクト-->
            <div
              class="ui-fluid"
              *ngIf="generateInputInformation.input_type == 'single_select'"
            >
              <p-dropdown
                [name]="generateInputInformation.column_id"
                [options]="generateInputInformation.column_code_list_multi"
                placeholder="　"
                optionLabel="name"
                [showClear]="true"
                [filter]="
                  generateInputInformation.column_code_list_multi.length >= 10
                "
                emptyFilterMessage="検索結果が存在しません"
                appendTo="body"
                [styleClass]="
                  checkErrorItem(generateInputInformation.column_id)
                    ? 'errorItem'
                    : ''
                "
                [formControl]="
                  generateInputForm.controls[generateInputInformation.column_id]
                "
              >
              </p-dropdown>
            </div>

            <!-- マルチセレクト -->
            <div
              class="ui-fluid"
              *ngIf="generateInputInformation.input_type == 'multiple_select'"
            >
              <p-multiSelect
                [name]="generateInputInformation.column_id"
                [options]="generateInputInformation.column_code_list_multi"
                defaultLabel="　"
                emptyFilterMessage="検索結果が存在しません"
                optionLabel="name"
                appendTo="body"
                [styleClass]="
                  checkErrorItem(generateInputInformation.column_id)
                    ? 'errorItem'
                    : ''
                "
                [formControl]="
                  generateInputForm.controls[generateInputInformation.column_id]
                "
              >
              </p-multiSelect>
            </div>

            <!-- テキストエリア -->
            <div
              class="ui-fluid"
              *ngIf="generateInputInformation.input_type == 'textarea'"
            >
              <textarea
                [name]="generateInputInformation.column_id"
                [maxlength]="generateInputInformation.column_hsize"
                rows="10"
                [class.errorItem]="
                  checkErrorItem(generateInputInformation.column_id)
                "
                [formControlName]="generateInputInformation.column_id"
                pInputTextarea
              ></textarea>
            </div>

            <!-- パスワード -->
            <div
              class="ui-fluid"
              *ngIf="generateInputInformation.input_type == 'password'"
            >
              <input
                [name]="generateInputInformation.column_id"
                type="password"
                [maxlength]="generateInputInformation.column_hsize"
                [class.errorItem]="
                  checkErrorItem(generateInputInformation.column_id)
                "
                [formControlName]="generateInputInformation.column_id"
                pInputText
              />
            </div>

            <!-- エラーメッセージ出力箇所 -->
            <app-validator-message
              [control]="
                generateInputForm.get(generateInputInformation.column_id)
              "
            >
            </app-validator-message>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </form>
  <div *ngIf="setItemFlag">
    <form [formGroup]="generateInputMaterialForm">
    <div>
      <br>
      <!-- 資材タイトル -->
      <p-table
        styleClass="p-datatable-sm generate-input"
      >
        <ng-template pTemplate="header">
          <tr>
            <th>資材</th>
          </tr>
        </ng-template>
      </p-table>
      <!-- ボタンエリア -->
      <div class="materialButtonArea">
        <button
          type="button"
          class="button"
          label="資材選択"
          (click)="selectMaterial()"
          pButton
        ></button>
        <button
          type="button"
          class="button"
          label="資材クリア"
          (click)="clearMaterial()"
          pButton
        ></button>
      </div>
      <div>
        <!-- 資材情報出力領域 -->
        <p-table
        [value]="subSearchResultsList"
        styleClass="p-datatable-striped master-datatable"
        >
        
          <!-- 資材選択結果のヘッダ表示 -->
          <ng-template pTemplate="header">
            <tr class="p-col-2">
              <!-- APIヘッダーの表示 -->
              <ng-container *ngFor="let subColumn of subColumnOrder">
                <!-- <th *ngIf="subColumn.field != 'col_var1'" [pSortableColumn]="subColumn.field"> -->
                <th *ngIf="subColumn.field != 'col_var1'">
                  {{ subColumn.header }}
                  <!-- <p-sortIcon [field]="subColumn.field"></p-sortIcon> -->
                </th>
              </ng-container>
            </tr>
          </ng-template>
          <!-- 入力情報出力箇所 -->
          <ng-template pTemplate="body" let-subList let-rowIndex="rowIndex">
            <tr>
              <ng-container *ngFor="let subColumn of subColumnOrder" >
                <td *ngIf="subColumn.field != 'config_count' && subColumn.field != 'col_var1'">
                  {{subList[subColumn.field]}}
                </td>
                <td *ngIf="subColumn.field == 'config_count'" class="input-form">
                <!-- 数字 -->
                  <div class="ui-fluid">
                    <input
                      [name]="subColumn.field+rowIndex"
                      type="number"
                      [value]="subList[subColumn.field]"
                      [class.errorItem]="
                        checkErrorItem(subColumn.field+rowIndex)
                      "
                      [formControlName]="subColumn.field+rowIndex"
                      pInputText class="p-inputtext-sm"
                      />
                  </div>
                    <!-- エラーメッセージ出力箇所 -->
                    <app-validator-message
                      [control]="
                        generateInputMaterialForm.get(subColumn.field+rowIndex)
                      "
                      >
                    </app-validator-message>
                </td>
              </ng-container>
            </tr>
          </ng-template>
        </p-table>
      </div>
      <app-generate-input-set-material (generateInputForm)="receiveSelectSetMain($event)"></app-generate-input-set-material>
    </div>
    </form>
  </div>

  <!-- ボタンエリア -->
  <div class="buttonArea">
    <button
      class="button"
      type="submit"
      label="入力チェック"
      (click)="inputVerification()"
      [disabled]="!generateInputMaterialForm.valid"
      pButton
    ></button>
  </div>
</div>

<!-- 入力確認 -->
<div class="generateInputConfirmation" *ngIf="confirmationFlag">
  <!-- 確認情報出力領域 -->
  <p-table
    [value]="generateInputFormConfirmationList"
    styleClass="p-datatable-sm"
  >
    <!-- 確認情報タイトル -->
    <ng-template pTemplate="header">
      <tr>
        <th colspan="2">確認</th>
      </tr>
    </ng-template>
    <!-- 確認情報出力箇所 -->
    <ng-template pTemplate="body" let-generateInputFormConfirmation>
      <tr>
        <!-- カラム名称 -->
        <td class="label-center">
          <label class="p-col-fixed">
            {{ generateInputFormConfirmation._columnName }}
          </label>
        </td>
        <!-- 入力項目値 -->
        <td *ngIf="generateInputFormConfirmation._columnId != 'quantity'" class="ui-fluid confirmationData">
          {{ generateInputFormConfirmation._columnData }}
        </td>
        <td *ngIf="generateInputFormConfirmation._columnId == 'quantity'" class="ui-fluid confirmationData">
          {{ generateInputFormConfirmation._columnData | number }}
        </td>
      </tr>
    </ng-template>
  </p-table>
  <div *ngIf="setItemFlag">
    <br>
    <!-- 資材情報出力領域 -->
    <p-table
    [value]="generateInputMaterialFormConfirmationList"
    styleClass="p-datatable-striped master-input"
    selectionMode="single"
    >
    <!-- 入力情報タイトル -->
    <ng-template pTemplate="header">
      <tr>
        <th colspan="3">資材</th>
      </tr>
      <!-- 資材のヘッダ -->
      <tr>
        <ng-container *ngFor="let subColumn of subColumnOrder">
          <th *ngIf="subColumn.field != 'col_var1'">
            {{subColumn.header}}
          </th>
        </ng-container>
      </tr>
    </ng-template>
    <!-- 入力情報出力箇所 -->
    <ng-template pTemplate="body" let-subSearchResults>
      <tr>
        <ng-container *ngFor="let subColumn of subColumnOrder">
          <td *ngIf="subColumn.field != 'col_var1'">
            {{subSearchResults[subColumn.field]}}
          </td>
        </ng-container>
      </tr>
      </ng-template>
    </p-table>
  </div>

  <!-- ボタンエリア -->
  <div class="buttonArea">
    <button
      class="button"
      type="button"
      label="登録"
      (click)="returnGenerateInputForm()"
      [disabled]="insertFlag"
      pButton
    ></button>
    <button
      class="button"
      type="button"
      label="戻る"
      (click)="returnConfirmationButton()"
      [disabled]="insertFlag"
      pButton
    ></button>
  </div>
</div>
