import { AfterViewInit, Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DbOperationService } from 'src/app/shared/service/db-operation.service';
import {
  API_URL_ORDER_ITEM,
  API_URL_ORDER_ITEM_DETAIL,
  API_URL_SEARCH_ORDER_ITEM,
  API_URL_SUB_WORK_ORDERS,
  API_URL_WORK_ORDER,
  API_URL_WORK_ORDER_ITEM,
} from 'manager/http-constants_key';
import { DataView } from 'primeng/dataview';

import { ActivatedRoute, Router } from '@angular/router';
import { GenerateDisplayComponent } from 'src/app/shared/generate/generate-display/generate-display.component';
import { SetMainDetailComponent } from 'src/app/pages/master/set-main/set-main-detail/set-main-detail.component';
import { InputGenerateDisplay } from 'src/app/shared/generate/generate-display/generate-display-information/generate-display-information';
import { ITEM_SEARCH_TEMPLATE } from 'manager/template-constant';
import { CommonService } from 'src/app/shared/service/common.service';
import { CONSTANT, DATE_FORMAT } from 'src/app/shared/constant/constant';
import { DatePipe } from '@angular/common';
import { ITEM_SEARCH_CONSTANT } from './constant';
import {
  MessageData,
  ToastMessageData,
} from 'src/app/shared/html-parts/message-common/message-data';
import {
  LoadData,
  LoadingState,
} from 'src/app/shared/html-parts/loading/loading-state';
import { MESSAGE_CODE } from 'src/app/shared/constant/message-constant';
import { TOAST } from 'src/app/shared/constant/primeng-constants';
import { ORDER$CATEGORY1 } from 'src/app/shared/constant/db-constant';
import { ExportFileService } from 'src/app/shared/html-parts/export-file/export-file.service';

@Component({
  selector: 'app-item-search',
  templateUrl: './item-search.component.html',
  styleUrls: ['../order.component.scss', './item-search.component.scss'],
})

/**
 * 発注販促資材画面
 */
export class ItemSearchComponent implements OnInit, AfterViewInit, OnDestroy {
  /** 詳細画面 */
  @ViewChild(GenerateDisplayComponent)
  generateDisplayItemsComponent: GenerateDisplayComponent;
  /** 詳細画面(セット品) */
  @ViewChild(SetMainDetailComponent)
  setMainDetailComponent: SetMainDetailComponent;

  // カテゴリ1(クエリパラメータ)
  // TODO 森永で同一ユーザで3画面切り分けるために使用
  category1: string = null;

  // 検索項目生成エンドポイント
  public searchEndPoint: string = API_URL_SEARCH_ORDER_ITEM;

  // 検索項目生成テンプレートID
  searchTemplateId: number;

  /* 画面用プロパティ */
  // 画面ヘッダー情報格納先
  columnOrder: any[] = new Array();

  // 画面検索結果一覧格納先
  searchResultsList: any[] = new Array();

  // カート選択リスト格納先
  cartSelected: any[] = new Array();

  // 全選択チェックボックス
  allChecked: boolean = false;

  // 検索条件保持
  generateSearchItems: Object;

  // dataView ソートカラム名
  sortField: string;

  // dataView ソート順 (1:昇順, -1:降順)
  sortOrder: number;
  private mainContainer: HTMLElement | null = null;
  private observer: MutationObserver | null = null;
  constructor(
    private dbOperationService: DbOperationService,
    private messageData: MessageData,
    private router: Router,
    private commonService: CommonService,
    public datePipe: DatePipe,
    private loadingState: LoadingState,
    private exportFileService: ExportFileService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    // パスパラメータの変更を検知
    // TODO 森永で同一ユーザで3画面切り分けるために使用
    this.route.paramMap.subscribe((paramsMap) => {
      // パスパラメータを格納
      this.category1 = paramsMap.get('category1');

      /* 検索テンプレートの設定 */
      // カテゴリ1(クエリパラメータ)の判定
      // TODO 森永で同一ユーザで3画面切り分けるために使用
      if (ORDER$CATEGORY1.CATEGORY1_NORMAL == this.category1) {
        // 通常の場合
        this.searchTemplateId = ITEM_SEARCH_TEMPLATE.NORMAL_SEARCH_TEMPLATE_ID;
      } else if (ORDER$CATEGORY1.CATEGORY1_NEW == this.category1) {
        // 新規の場合
        this.searchTemplateId = ITEM_SEARCH_TEMPLATE.NEW_SEARCH_TEMPLATE_ID;
      } else if (ORDER$CATEGORY1.CATEGORY1_SEND == this.category1) {
        // 送込の場合
        this.searchTemplateId = ITEM_SEARCH_TEMPLATE.SEND_SEARCH_TEMPLATE_ID;
      }

      // ワークテーブル削除処理
      this.dbOperationService
        .deleteData(API_URL_SUB_WORK_ORDERS, 'work_order_item')
        .subscribe(() => {
          console.log(
            this.commonService.msg(MESSAGE_CODE.D00005, '資材選択情報')
          );
        });

      // 画面ロードフラグをON(ロード中状態)
      this.loadingState.loadStart();

      /* ヘッダー情報取得処理(画面用) */
      this.dbOperationService
        .getHeaderList(
          // カテゴリ1(クエリパラメータ)の判定
          // TODO 森永で同一ユーザで3画面切り分けるために使用
          ORDER$CATEGORY1.CATEGORY1_NORMAL == this.category1
            ? // 通常の場合
              ITEM_SEARCH_TEMPLATE.NORMAL_SEARCH_RESULTS_TEMPLATE_ID
            : // カテゴリ1(クエリパラメータ)の判定
            ORDER$CATEGORY1.CATEGORY1_NEW == this.category1
            ? // 新規の場合
              ITEM_SEARCH_TEMPLATE.NEW_SEARCH_RESULTS_TEMPLATE_ID
            : // 送込の場合
              ITEM_SEARCH_TEMPLATE.SEND_SEARCH_RESULTS_TEMPLATE_ID
        )
        .subscribe((response) => {
          this.columnOrder = response.body;
        });

      // 検索処理を実施
      this.searchResult();
    });
  }
  ngAfterViewInit() {
    this.setMainContainer();

    // Theo dõi sự thay đổi trong DOM để cập nhật mainContainer nếu cần
    this.observer = new MutationObserver(() => {
      this.setMainContainer();
    });

    this.observer.observe(document.body, { childList: true, subtree: true });
  }
  ngOnDestroy() {
    if (this.mainContainer) {
      this.mainContainer.removeEventListener('scroll', this.onScroll);
    }
  }
  private setMainContainer() {
    const newContainer = document.querySelector('.mat-drawer-content') as HTMLElement;
    
    if (newContainer && newContainer !== this.mainContainer) {
      // Xóa sự kiện cũ nếu có
      if (this.mainContainer) {
        this.mainContainer.removeEventListener('scroll', this.onScroll);
      }

      // Gán container mới
      this.mainContainer = newContainer;
      this.mainContainer.addEventListener('scroll', this.onScroll);
    }
  }
  /**
   * 検索処理
   * @param table テーブル状態
   * @param generateSearchItems 検索条件
   */
  public searchResult(table?: DataView, generateSearchItems?: Object) {
    // カート選択リストを初期化
    this.cartSelected = new Array();

    // 画面カスタムロードフラグをON(ロード中状態)
    this.loadingState.customLoadStart(
      new LoadData({
        loadingText: this.commonService.msg(MESSAGE_CODE.S00003),
      })
    );

    // 検索条件に無条件でカテゴリを追加
    // TODO 森永案件で使用
    {
      // 初期表示か否か
      if (!generateSearchItems) {
        // 初期表示の場合

        // 検索条件オブジェクトを生成
        generateSearchItems = new Object();
      }

      // 検索条件にカテゴリ1を追加
      // generateSearchItems['category1'] = this.category1;
    }

    // CSV出力用に検索条件を保持
    this.generateSearchItems = generateSearchItems;

    let unescapeData: any;
    /* 販促資材マスタ一覧取得処理(画面用) */
    this.dbOperationService
      .getData(
        API_URL_ORDER_ITEM,
        // カテゴリ1(クエリパラメータ)の判定
        // TODO 森永で同一ユーザで3画面切り分けるために使用
        ORDER$CATEGORY1.CATEGORY1_NORMAL == this.category1
          ? // 通常の場合
            ITEM_SEARCH_TEMPLATE.NORMAL_SEARCH_RESULTS_TEMPLATE_ID
          : // カテゴリ1(クエリパラメータ)の判定
          ORDER$CATEGORY1.CATEGORY1_NEW == this.category1
          ? // 新規の場合
            ITEM_SEARCH_TEMPLATE.NEW_SEARCH_RESULTS_TEMPLATE_ID
          : // 送込の場合
            ITEM_SEARCH_TEMPLATE.SEND_SEARCH_RESULTS_TEMPLATE_ID,
        generateSearchItems
      )
      .subscribe((response) => {
        // コード値の一覧情報が存在するか否か
        if (!this.commonService.checkNoneResponse(response)) {
          // 販促資材マスタ一覧情報のJSONをオブジェクトに格納する。htmlエスケープされた文字を戻す
          unescapeData = response.body
          console.log(typeof unescapeData)
          for (let index = 0; index < unescapeData.length; index++) {
            for (const key in unescapeData[index]) {
              if (Object.prototype.hasOwnProperty.call(unescapeData[index], key)) {
                const element = unescapeData[index][key];
                if (typeof element === 'string' && element !== ''){
                  unescapeData[index][key] = element.replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&amp;/g, '&');
                }
              }
            }
          };
          this.searchResultsList = unescapeData;
        } else {
          this.searchResultsList = new Array();
        }

        // 画面ロードフラグをOFF(ロード終了)
        this.loadingState.loadSleepEnd(0.3);
      });
  }

  /**
   * 詳細情報画面表示
   * @param pkeyId IDコード(クリックされたID)
   * @param kbn 区分
   */
  protected detail(pkeyId: string, kbn: string) {
    // 詳細画面表示
    if(kbn == "セット品" || kbn == "SET"){
      this.setMainDetailComponent.initial(pkeyId);
    } else {
      let inputGenerateDisplay: InputGenerateDisplay = new InputGenerateDisplay();
      // inputGenerateDisplay.endPoint = API_URL_ORDER_ITEM;
      inputGenerateDisplay.endPoint = API_URL_ORDER_ITEM_DETAIL;
      inputGenerateDisplay.templateId = ITEM_SEARCH_TEMPLATE.OUTPUT_TEMPLATE_ID;
      this.generateDisplayItemsComponent.initial(inputGenerateDisplay, pkeyId);
    }
  }

  /**
   * カート追加
   */
  public addCart() {
    // カートが選択されているか否か
    if (!this.cartSelected.length) {
      // カートが選択されていない場合

      // 警告メッセージ
      this.messageData.toastMessage(
        new ToastMessageData({
          severity: TOAST.WARN,
          summary: this.commonService.msg(MESSAGE_CODE.E00015),
          detail: this.commonService.msg(MESSAGE_CODE.E00016, '資材'),
        })
      );

      return;
    }

    // 画面カスタムロードフラグをON(ロード中状態)
    this.loadingState.customLoadStart(
      new LoadData({
        loadingText: this.commonService.msg(MESSAGE_CODE.I00012),
      })
    );

    // カート選択リスト格納先から資材コードのみを取得する
    const code = this.commonService.createArrayGetArrayObject(
      this.cartSelected,
      this.commonService.getPkeyColumn(this.columnOrder)
    );

    // カート登録
    this.dbOperationService
      .insertData(API_URL_WORK_ORDER_ITEM, {
        item_code: code.join(CONSTANT.COMMA),
        // category1: this.category1,
      })
      .subscribe((response) => {
        // レスポンスを判定
        if (
          this.messageData.responseToastMessage(
            response,
            this.commonService.msg(MESSAGE_CODE.I00014),
            this.commonService.msg(MESSAGE_CODE.I00015, '資材')
          )
        ) {
          // レスポンスが正常終了の場合

          // TODO 森永案件のみ処理を追加
          // 注文情報(WORK)登録
          this.dbOperationService
            .insertData(API_URL_WORK_ORDER, {
              category1: this.category1,
            })
            .subscribe((response) => {
              // 画面ロードフラグをOFF(ロード終了)
              this.loadingState.loadEnd();

              // レスポンスを判定
              if (this.commonService.checkRunningNormallyResponse(response)) {
                // レスポンスが正常終了の場合

                // 発注管理_数量へ遷移
                // TODO 森永で同一ユーザで3画面切り分けるために使用
                this.router.navigate([
                  'pages/order/quantity/' + this.category1,
                ]);
              } else {
                // 発注管理_届先選択へ遷移
                // TODO 森永で同一ユーザで3画面切り分けるために使用
                this.router.navigate([
                  'pages/order/delivery-search/' + this.category1,
                ]);
              }
            });
        }
      });
  }

  /**
   * CSV出力
   */
  protected exportCSV() {
    // csvファイル名の設定
    const fileName =
      ITEM_SEARCH_CONSTANT.CSV_FILENAME +
      CONSTANT.UNDERBAR +
      this.datePipe.transform(new Date(), DATE_FORMAT.DATETIME_HYPHEN);

    // CSVファイル出力
    this.exportFileService.exportTemplateCsv(
      fileName,
      null,
      API_URL_ORDER_ITEM,
      // カテゴリ1(クエリパラメータ)の判定
      // TODO 森永で同一ユーザで3画面切り分けるために使用
      ORDER$CATEGORY1.CATEGORY1_NORMAL == this.category1
        ? // 通常の場合
          ITEM_SEARCH_TEMPLATE.NORMAL_CSV_TEMPLATE_ID
        : // カテゴリ1(クエリパラメータ)の判定
        ORDER$CATEGORY1.CATEGORY1_NEW == this.category1
        ? // 新規の場合
          ITEM_SEARCH_TEMPLATE.NEW_CSV_TEMPLATE_ID
        : // 送込の場合
          ITEM_SEARCH_TEMPLATE.SEND_CSV_TEMPLATE_ID,
      this.generateSearchItems
    );
  }

  /**
   * プライマリーカラムを取得
   */
  protected getPkeyColumn(): string {
    return this.commonService.getPkeyColumn(this.columnOrder);
  }

  /**
   * Newマーク表示
   * @param updateDatetime システム更新日付
   * @returns true:(システム更新日付が現在時間-7日より前) false:(システム更新日付が現在時間-7日より後)
   */
  // TODO 森永でNEWマークを表示用に使用
  protected outputNewMark(updateDatetime: Date): boolean {
    // システム更新日付が取得できない場合
    if (!updateDatetime) {
      return false;
    }

    // 1週間前(現在時間から7日前を取得)
    const nowDate = this.datePipe.transform(
      new Date().setDate(new Date().getDate() - 7),
      DATE_FORMAT.DATE
    );

    // システム更新日付が1週間前より前か否か
    if (nowDate >= this.datePipe.transform(updateDatetime, DATE_FORMAT.DATE)) {
      // システム更新日付が1週間前より後の場合

      return false;
    }

    return true;
  }

  /**
   * 画面スクロール実行時に実行
   */
  private onScroll = (event: Event) => {
    const mainDiv = event.target as HTMLElement;
    if (!mainDiv) return;

    const scrollTop = mainDiv.scrollTop;
    const pageTopButton = document.getElementById('addCartButton');

    if (pageTopButton) {
      if (scrollTop > 100) {
        pageTopButton.classList.add('show');
      } else {
        pageTopButton.classList.remove('show');
      }
    }
  };

  /**
   * チェックボックス全選択
   */
  protected setCheckAll(dv: DataView, event: PointerEvent) {
    event.preventDefault()
    if(this.allChecked){
      this.cartSelected = new Array();
      this.allChecked = false;
    }else{
      this.cartSelected = this.searchResultsList.slice(dv.first, dv.first + dv.rows);
      this.allChecked = true;
    }
  }
  
  /**
   * dataView ソート処理
   */
   protected onSortChange(column: string) {
    let setOrder;
    if(this.sortField == column){
      setOrder = this.sortOrder == 1 ? -1 : 1;
    }else{
      setOrder = 1
    }
    this.sortOrder = setOrder;
    this.sortField = column;
  }
}
