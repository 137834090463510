import { Component, AfterViewInit, OnDestroy } from '@angular/core';
import { TITLE } from '../../../../../manager/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements AfterViewInit, OnDestroy {
  title: string = TITLE.TITLE_FOOTER;
  private mainContainer: HTMLElement | null = null;
  private observer: MutationObserver | null = null;

  constructor() {}

  ngAfterViewInit() {
    this.setMainContainer();

    this.observer = new MutationObserver(() => {
      this.setMainContainer();
    });

    this.observer.observe(document.body, { childList: true, subtree: true });
  }

  ngOnDestroy() {
    if (this.mainContainer) {
      this.mainContainer.removeEventListener('scroll', this.onScroll);
    }
    if (this.observer) {
      this.observer.disconnect();
    }
  }
  private setMainContainer() {
    const newContainer = document.querySelector('.mat-drawer-content') as HTMLElement;
    
    if (newContainer && newContainer !== this.mainContainer) {
      if (this.mainContainer) {
        this.mainContainer.removeEventListener('scroll', this.onScroll);
      }
      this.mainContainer = newContainer;
      this.mainContainer.addEventListener('scroll', this.onScroll);
    }
  }

  /**
   * 画面スクロール実行時に実行
   */
  private onScroll = (event: Event) => {
    const mainDiv = event.target as HTMLElement;
    if (!mainDiv) return;

    const scrollTop = mainDiv.scrollTop;
    const pageTopButton = document.getElementById('pageTopButton');

    if (pageTopButton) {
      if (scrollTop > 50) {
        pageTopButton.removeAttribute('disabled');
      } else {
        pageTopButton.setAttribute('disabled', 'true');
      }
    }
  };

  public transitionPageTop() {
    this.mainContainer?.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }
}
